import React from "react"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import "./GeneralEnquiryModule.scss"
import linesBg from "../../images/lines-bg.svg"
import GeneralEnquiryForm from "../forms/GeneralEnquiryForm"
import clsx from "clsx"

const GeneralEnquiryModule = ({ module, teamEnquiry, locationState }) => {
  const { title, module_content } = teamEnquiry
    ? { title: "Team Enquiry" }
    : module

  const team = locationState?.team

  return (
    <div
      className={clsx("general-enquiry-module-wrapper section-p", {
        "team-enquiry-module-wrapper margin-for-header": teamEnquiry,
      })}
      id="enquiry-module"
    >
      <img src={linesBg} className="lines-img" />

      <Container className="general-enquiry-module-container">
        <div className="left-section">
          <h4 className="title">{title}</h4>
          {module_content && (
            <div className="content">{parse(module_content)}</div>
          )}
          {teamEnquiry && team && (
            <div className="team-content">
              <h3>
                <span className="pre-text">Contact</span> <br />
                <span>{team?.name}</span>
              </h3>
            </div>
          )}
        </div>
        <div className="right-section">
          <GeneralEnquiryForm toEmail={team?.email} />
        </div>
      </Container>
    </div>
  )
}

export default GeneralEnquiryModule
