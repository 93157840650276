import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { useState } from "react"
import { Container } from "react-bootstrap"
import Select from "react-select"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
import "./VideoListing.scss"
//import { useAutoAnimate } from "@formkit/auto-animate/react"
import VideoCard from "./VideoCard"

const VideoListing = ({ module }) => {
  //const [parent] = useAutoAnimate({ duration: 300 })

  const data = useStaticQuery(graphql`
    query GetAllVideos {
      glstrapi {
        videos(sort: "date:desc") {
          id
          title
          video_url
          date
          slug
          thumbnail {
            url
          }
          ggfx_results {
            src_cftle
            transforms
          }
          video_category {
            title
          }
        }
        videoCategories {
          title
          slug
          id
          videos(sort: "date:desc") {
            id
            video_url
            title
            date
            thumbnail {
              url
            }
            ggfx_results {
              src_cftle
              transforms
            }
            video_category {
              title
              slug
            }
          }
        }
      }
    }
  `)

  const allVideos = data.glstrapi.videos
  const videosCategories = data.glstrapi.videoCategories

  const { isMobile, isTablet } = useDeviceMedia()

  const allCategoryOption = {
    label: "All Videos",
    value: "all",
  }

  const [selectedOption, setSelectedOption] = useState(allCategoryOption)

  // generate all the category options
  const selectOptions = [
    allCategoryOption,
    ...videosCategories.map(category => {
      return {
        value: category.slug,
        label: category.title,
      }
    }),
  ]

  // Category tab logic
  const [selectedVideos, setSelectedVideos] = useState(allVideos)

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedVideos])

  const handleTabChange = option => {
    setSelectedOption(option)

    if (option.value === "all") {
      setSelectedVideos(allVideos)
      return
    }

    const filteredVideos = videosCategories.filter(
      videoCategory => option.value === videoCategory.slug
    )[0]?.videos

    setSelectedVideos(filteredVideos)
  }
  // Category tab logic

  // for pagination
  const itemsPerPage = isMobile ? 18 : isTablet ? 10 : 6
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedVideos,
    itemsPerPage,
  })
  // for pagination

  return (
    <div className="videos-listing-wrap">
      <Container className="videos-listing-container">
        <div className="category-container">
          {isTablet ? (
            <Select
              options={selectOptions}
              isSearchable={false}
              className={"category-select"}
              classNamePrefix={"category-select"}
              value={selectedOption}
              onChange={handleTabChange}
            />
          ) : (
            <div className="category-tab-section">
              {selectOptions?.map(option => (
                <button
                  className={`category-tab button button-primary ${
                    selectedOption.value === option.value ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(option)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="videos-section">
          <div className="videos-grid">
            {currentItems?.map(videos => (
              <VideoCard videos={videos} />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            currentItems={currentItems}
            itemsPerPage={itemsPerPage}
            totalItems={selectedVideos.length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Container>
    </div>
  )
}

export default VideoListing
