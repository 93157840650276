import clsx from "clsx"
import parse from "html-react-parser"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import "./StatsModule.scss"

const StatsModule = ({ module }) => {
  if (!module) return null
  let len = module.stats.length
  
  const getClass = () => {
    let className = "";
    if (len === 1) {
        className = 'grid-column-1'
    } else if (len === 2) {
        className = 'grid-column-2'
    } else if (len === 3) {
        className = 'grid-column-3'
    } else {
        className = 'grid-column-4'
    }
    return className
  }

  return (
    <section className="stats-module-wrapper section-p">
      <Container className="stats-module-container">
        <h4 className="heading">{module.heading}</h4>
        <div className="content">{parse(module.content)}</div>
        {!_.isEmpty(module.stats) && (
          <div className={clsx("stats-section", getClass())}>
            {module.stats.map(stat => (
              <div
                className={clsx("stat-item", {
                  "stat-item-icon": stat.icon_class && stat.icon_class !== "",
                })}
              >
                <i className={`icon ${stat.icon_class}`}></i>
                <p className="text">{stat.stat_text}</p>
                <p className="description">{parse(stat.stat_description)}</p>
              </div>
            ))}
          </div>
        )}
      </Container>
    </section>
  )
}

export default StatsModule
