import React, {useEffect} from "react"
import { Helmet } from "react-helmet"
import BannerSearch from "../components/BannerSearch/BannerSearch"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import AboutTeamModule from "../components/AboutTeamModule/AboutTeamModule"
import BeInspiredSlider from "../components/BeInspiredSlider/BeInspiredSlider"
import CommercialSlider from "../components/BeInspiredSlider/CommercialSlider"
import PopularAreasSlider from "../components/PopularAreasSlider/PopularAreasSlider"
import TileBlockModule from "../components/TileBlockModule/TileBlockModule"
import ListYourPropertyBanner from "../components/ListYourPropertyBanner/ListYourPropertyBanner"
import BannerLanding from "../components/BannerLanding/BannerLanding"
import TeamListing from "../components/TeamListing/TeamListing"
import ContactModule from "../components/ContactModule/ContactModule"
import GeneralEnquiryModule from "../components/GeneralEnquiryModule/GeneralEnquiryModule"
import AreaguideListing from "../components/AreaguideListing/AreaguideListing"
import NewsListing from "../components/NewsListing/NewsListing"
import StatsModule from "../components/StatsModule/StatsModule"
import FAQModule from "../components/FAQModule/FAQModule"
import TeamSlider from "../components/TeamSlider/TeamSlider"
import ListYourProperty from "../components/ListYourProperty/ListYourProperty"
import ReviewsModule from "../components/ReviewsModule/ReviewsModule"
import UsefulLinksModule from "../components/UsefulLinksModule/UsefulLinksModule"
import AwardsSlider from "../components/AwardsSlider/AwardsSlider"
import StaticModule from "../components/StaticModule/StaticModule"
import BookViewingModule from "../components/BookViewingModule/BookViewingModule"
import SendCvModule from "../components/SendCvModule/SendCvModule"
import MakeAComplaint from "../components/MakeAComplaint/MakeAComplaint"
import RegisterInterest from "../components/RegisterInterest/RegisterInterest"
import MakeAnEnquiry from "../components/MakeAnEnquiry/MakeAnEnquiry"
import VideoListing from "../components/VideoListing/VideoListing"
import HomeTileBlockModule from "../components/HomeTileBlockModule/HomeTileBlockModule"

const CommonTemplate = props => {
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  const bannerSection = GQLPage.banner_section
  const ggfx_results = GQLPage.ggfx_results
  const locationState = props.locationState
  const wrapClass = GQLPage?.alias ? `page-${GQLPage?.alias.toLowerCase().replace(/\s+/g, '-')}-wrap` : '';

  return (
    <div className={`${GQLPage.page_class ? GQLPage.page_class : ""}`}>
      <Helmet>
        <body className={wrapClass ? wrapClass : ''} />
      </Helmet>
      <Header layout={GQLPage?.layout} />
      {GQLPage?.layout === "home_template" && (
        <BannerSearch
          ggfx_results={ggfx_results}
          bannerSection={bannerSection}
          imagetransforms={GQLPage.imagetransforms}
          id={GQLPage.id}
          alias={GQLPage?.alias}
        />
      )}
      {GQLPage?.layout === "landing_template" && (
        <BannerLanding bannerSection={bannerSection} />
      )}
      {GQLPage?.layout === "contact_page_template" && <></>}

      {GQLModules.map((module, i) => {
        return (
          <>
            {module.__typename ===
              "GLSTRAPI_ComponentModulesAboutAndTeamsModule" && (
              <AboutTeamModule module={module} />
            )}

            {module.__typename === "GLSTRAPI_ComponentModulesTileBlock" && (
              <TileBlockModule module={module} GQLPage={GQLPage} />
            )}

            {module.__typename === "GLSTRAPI_ComponentModulesHomeTileBlock" && (
              <HomeTileBlockModule module={module} GQLPage={GQLPage} />
            )}

            {module.__typename === "GLSTRAPI_ComponentComponentsFaq" && (
              <FAQModule module={module} />
            )}
            {module.__typename === "GLSTRAPI_ComponentModulesBookAValuation" &&
              GQLPage.choose_form === "book_a_valuation" && (
                <ListYourProperty module={module} layout={GQLPage?.layout} />
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesBookAValuation" &&
              GQLPage.choose_form === "make_a_complaint" && (
                <MakeAComplaint module={module} layout={GQLPage?.layout}/>
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesBookAValuation" &&
              GQLPage.choose_form === "register_interest" && (
                <RegisterInterest module={module} layout={GQLPage?.layout}/>
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesBookAValuation" &&
              GQLPage.choose_form === "make_an_enquiry" && (
                <MakeAnEnquiry module={module} layout={GQLPage?.layout}/>
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesBookAValuation" &&
              GQLPage.choose_form === "book_a_viewing" && (
                <BookViewingModule module={module} layout={GQLPage?.layout}/>
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesBookAValuation" &&
              GQLPage.choose_form === "team_enquiry" && (
                <GeneralEnquiryModule
                  locationState={locationState}
                  teamEnquiry
                  layout={GQLPage?.layout}
                />
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesBookAValuation" &&
              GQLPage.choose_form === "careers_form" && (
                <SendCvModule module={module} layout={GQLPage?.layout}/>
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesReviewsModule" && (
              <ReviewsModule module={module} GQLPage={GQLPage} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsUserfulLinksModule" && (
              <UsefulLinksModule module={module} GQLPage={GQLPage} />
            )}

            {module.__typename === "GLSTRAPI_ComponentModulesStatsBanner" && (
              <StatsModule module={module} />
            )}
            {module.__typename === "GLSTRAPI_ComponentBlocksRichText" &&
              GQLPage.layout === "static_template" && (
                <StaticModule module={module} page={bannerSection} />
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesTeamSlider" && (
              <TeamSlider module={module} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "popular_areas_slider" && (
                <PopularAreasSlider module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "awards_slider" && (
                <AwardsSlider module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "featured_properties_slider" && (
                <BeInspiredSlider module={module} />
              )}
             {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "commercial_properties_slider" && (
                <CommercialSlider module={module} />
              )}
              

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "team_listing" && <TeamListing />}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "areaguide_listing" && (
                <AreaguideListing />
              )}
            {module.__typename === "GLSTRAPI_ComponentModulesNewsListing" && (
              <NewsListing module={module} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "videos_listing" && (
                <VideoListing module={module} />
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesSelectModule" &&
              module.module === "list_your_property_banner" && (
                <ListYourPropertyBanner />
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesSelectModule" &&
              module.module === "contact_module" && (
                <ContactModule module={module} layout={GQLPage?.layout}/>
              )}

            {module.__typename === "GLSTRAPI_ComponentModulesSelectModule" &&
              module.module === "general_enquiry_module" && (
                <GeneralEnquiryModule module={module} layout={GQLPage?.layout}/>
              )}
          </>
        )
      })}

      <Footer categoryType={GQLPage.Popular_Search} />
    </div>
  )
}

export default CommonTemplate
