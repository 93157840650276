import React, { useRef, useEffect } from "react"
import { formTracking } from "../../site/utils"
import { SendMail } from "../common/site/utils"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import FORM_CONFIG, { SUCCESS_MSG_CAREER } from "./config"
import FileInput from "./elements/FileInput"
import Form from "./elements/Form"
import TextInput from "./elements/TextInput"
import TextAreaInput from "./elements/TextAreaInput"
import Select from "react-select"
import Recaptcha from "./inputs/Recaptcha"
import { careersSchema } from "./schemas"
import $ from "jquery"

const CareersForm = () => {

  const options = [
      { value: '', label: 'Select option' },
      { value: 'Property Broker', label: 'Property Broker' },
      { value: 'HR / Recruiter', label: 'HR / Recruiter' },
      { value: 'Marketing', label: 'Marketing' },
      { value: 'Administrator', label: 'Administrator' },
      { value: 'Accountant', label: 'Accountant' },
      { value: 'IT', label: 'IT' },
      { value: 'Conveyancer', label: 'Conveyancer' },
      { value: 'Property Manager / Holiday Homes', label: 'Property Manager / Holiday Homes' },
      { value: 'Photographer / Videographer', label: 'Photographer / Videographer' },
      { value: 'Graphic Designer', label: 'Graphic Designer' },
      { value: 'Interior Designer', label: 'Interior Designer' },
      { value: 'Office Assistant', label: 'Office Assistant' }
  ]

  useEffect(() => {
    $("form").on( "submit", function( event ) {
      var applyPosition = $("input[name=applyposition]").val();
      if (applyPosition) {
        $('.position-select .error-msg').addClass('d-none');
      } else {
        $('.position-select .error-msg').removeClass('d-none');
      }
    });
  }, [])

  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.careers }
      let formData = new FormData()

      if (postData.attachment) {
        formData.append(
          "files.attachment",
          postData.attachment[0],
          postData.attachment[0].name
        ) // appending file
        delete postData.attachment
      }

      if (postData.attachphoto) {
        formData.append(
          "files.attachphoto",
          postData.attachphoto[0],
          postData.attachphoto[0].name
        ) // appending file
        delete postData.attachphoto
      }

      postData["apply_position"] = $("input[name=applyposition]").val();
      //Shallow clone for Google data
      postData["google_data"] = {...postData, "attachment_mapping":{"attachment":"file_url", "attachphoto":"photo_url"}};
     
      formData.append("data", JSON.stringify(postData))

      recaptchaRef.current.reset()
      postFormData(formData).then(async apiRes => {
        postData["file_url"] = `${apiRes?.form?.attachment?.url}`
        postData["photo_url"] = `${apiRes?.form?.attachphoto?.url}`
        postData['g-recaptcha-response'] = token;
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.careers.event_tracking,
        form_name: FORM_CONFIG.careers.form_name,
      })
    }
  }

  return (
    <>
      <Form
        className="base-form general-enquiry-form"
        validationSchema={careersSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG_CAREER}
      >
        <TextInput name="name" label="Name*" />
        <TextInput name="email" label="Email*" type="email" />
        <TextInput name="telephone" label="Phone Number*" type="number" />
        <TextInput name="age" label="Age*" type="number" />
        <TextInput name="nationality" label="Nationality*" />
        <TextInput name="location" label="Location*" />
        <div class="input-box position-select form-field-group dropdown">
          <label className="input-label">Position applying for*</label>
          <Select
            name="applyposition"
            options={options}
            isSearchable={false}
            classNamePrefix="select-opt"
            className="select-control"
            placeholder="Select option"
            components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
          />
          <p class="error-msg d-none">Please choose your position</p>
        </div>
        <FileInput
          name="attachment"
          label="Attach CV*"
          accept=".pdf,.doc"
        />
        <FileInput
          name="attachphoto"
          label="Attach photo headshot*"
          accept=".jpg,.png"
        />
        <TextAreaInput name="message" label="Comments" />
        <button type="submit" className="button button-primary">
          submit
        </button>
        <TermsPolicySection />
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
    </>
  )
}

export default CareersForm
