import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby"
import _ from "lodash"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { areaguidesPageURL } from '../../site/urls';
import './assets/styles/_index.scss';
import { ImageModule } from "../common/Image_Module"
import NoImg from "../../images/no-image.png";

const PopularAreasSlider = ({module}) => {

    const data = useStaticQuery(graphql`
        query GetAreaguidesList {
            glstrapi {
                areaGuides(limit:6) {
                name
                id
                slug
                short_description
                tile_image {
                    url
                }
                ggfx_results {
                    src_cftle
                    transforms
                }
                }
            }
        }
    `)

    const allAreas = data?.glstrapi.areaGuides;
        
    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <section className="popular-areas-slider-wrapper">
            <Container>
                <Row>
                    <Col className="text-center">
                        <p className="small-header-text">{module?.title ? module?.title : "Popular Areas"}</p>
                        <h2>{module?.heading ? module?.heading : "Explore Dubai"}</h2>
                        {
                            !_.isEmpty(allAreas) &&
                            <Slider className="popular-areas-slider" {...settings}>
                                {
                                    allAreas.map((data, key) => {
                                        return (
                                            <div className="popular-areas-slide">
                                                <div className="popular-areas-img-zoom">
                                                    <Link to={areaguidesPageURL+data?.slug+"/"}>
                                                        {data.tile_image.url ? (
                                                            <ImageModule
                                                                imagename={"area-guides.tile_image.tileimg"}
                                                                ImageSrc={data.tile_image}
                                                                ggfx_results={data.ggfx_results}
                                                                altText={data.name + "- White & Co Real Estate"}
                                                                strapi_id={data.id}
                                                            />
                                                        ) : (
                                                            <img src={NoImg} alt={"area_guides - img"} />
                                                        )}
                                                    </Link>
                                                </div>
                                                <div className="popular-areas-text-wrapper">
                                                    <Link to={areaguidesPageURL+data?.slug+"/"}>
                                                        <div className="area-details">{data?.name}</div>
                                                    </Link>
                                                    <p className="display-address">{data?.short_description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularAreasSlider