import React, {useState} from "react"
import Slider from "react-slick"
import parse from "html-react-parser"
import "./ReviewTextModule.scss"

const ReviewTextModule = ({ reviews }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <div className="star-icons">
        {/* Render full stars */}
        {Array.from({ length: fullStars }).map((_, i) => (
          <span key={`filled-${i}`} className="filled-star">
            <i className="icon icon-full-star-black"></i>
          </span>
        ))}

        {/* Render half star */}
        {hasHalfStar && (
          <span className="half-star">
            <i className="icon icon-half-star-black"></i>
          </span>
        )}

        {/* Render empty stars */}
        {Array.from({ length: emptyStars }).map((_, i) => (
          <span key={`empty-${i}`} className="empty-star">
            <i className="icon icon-empty-star-black"></i>
          </span>
        ))}
      </div>
    )
  }

  const ReviewItem = ({ review }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    
    // Maximum number of characters to show before "Read More"
    const maxPreviewLength = 200;

    // Truncate the review content if it exceeds the maximum preview length
    const previewContent = review.review_content.length > maxPreviewLength
      ? review.review_content.substring(0, maxPreviewLength) + "..."
      : review.review_content;

    return (
      <div className="review-item">
        <StarRating rating={parseFloat(review.ratings)} />
        <div className="review-content">
          {parse(isExpanded ? review.review_content : previewContent)}
          {review.review_content.length > maxPreviewLength && (
            <button
              className="read-more-toggle"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
        <div className="review-author">
          <strong>{review.customer_name}</strong>
        </div>
      </div>
    )
  }

  return (
    <div className="review-text-slider-wrapper">
      <div className="review-text-slider-container">
        <Slider className="review-text-slider" {...settings}>
          {reviews.map(review => (
            <ReviewItem key={review.customer_name} review={review} />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default ReviewTextModule