import React, { useState, useEffect } from "react";

export default function SearchFormHolidayHomes() {
    useEffect(() => {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://d2q3n06xhbi0am.cloudfront.net/widget.js?1640277196';
        script.onload = function () {
            window.searchBar({
                baseUrl: 'https://www.whiteandcoholidayhomes.com/',
                showLocation: true,
                color: '#000',
                rounded: true,
                openInNewTab: false,
                font: 'Open Sans',
            });
        };
        script.onerror = function () {
            
        };
        document.body.appendChild(script);
    }, []);
    return (
        <section className="search-form-wrapper home-banner-search-form holiday-homes-banner">
            <div className="search-form-features d-flex align-items-center justify-content-center" style={{ "border-radius": "1.5rem", margin:"auto" }}>
                <div className="search-bar-wrapper predictive-search-box banner-sarch-wrapper"  >
                    <div id="hostaway-booking-widget"></div>
                </div>
            </div>
        </section>
    )
}