import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { useState } from "react"
import { Container } from "react-bootstrap"
import Select from "react-select"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
import "./NewsListing.scss"
//import { useAutoAnimate } from "@formkit/auto-animate/react"
import NewsCard from "../NewsCard/NewsCard"
import NewsletterForm from "../forms/NewsletterForm"
import { privacyURL, termsURL } from "../../site/urls"
import NewsCardFeatured from "../NewsCard/NewsCardFeatured"

const NewsListing = ({ module }) => {
  //const [parent] = useAutoAnimate({ duration: 300 })

  const data = useStaticQuery(graphql`
    query GetNewsLandingData {
      glstrapi {
        blogCategories {
          name
          slug
          id
          blogs {
            title
            post_on
            blog_category {
              name
              slug
              id
            }
            short_description
            tile_image {
              url
            }
            ggfx_results {
              src_cftle
              transforms
            }
            slug
          }
        }
        blogs(sort: "post_on:DESC") {
          title
          post_on
          id
          blog_category {
            name
            slug
            id
          }
          short_description
          tile_image {
            url
          }
          ggfx_results {
            src_cftle
            transforms
          }
          slug
        }
      }
    }
  `)

  const allNews = data.glstrapi.blogs
  const newsCategories = data.glstrapi.blogCategories
  const allFeaturedNews = module.featured_blogs

  const { isMobile, isTablet } = useDeviceMedia()

  const allCategoryOption = {
    label: "All News",
    value: "all",
  }

  const [selectedOption, setSelectedOption] = useState(allCategoryOption)
  // generate all the category options
  const selectOptions = [
    allCategoryOption,
    ...newsCategories.filter(item => {
      let hasCategory = false;
      allFeaturedNews.forEach(element => {
        if(element?.blog_category?.slug == item?.slug) {
          hasCategory = true
        }
      });
      return hasCategory
    }).map(category => {
      return {
        label: category.name,
        value: category.slug,
      }
    }),
  ]

  // Category tab logic
  const [selectedNews, setSelectedNews] = useState(allNews)

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedNews])

  const handleTabChange = option => {
    setSelectedOption(option)

    if (option.value === "all") {
      setSelectedNews(allNews)
      return
    }

    const filteredNews = newsCategories.filter(
      newsCategory => option.value === newsCategory.slug
    )[0]?.blogs
    setSelectedNews(filteredNews)
  }
  // Category tab logic

  // for pagination
  const itemsPerPage = isMobile ? 18 : isTablet ? 10 : 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedNews,
    itemsPerPage,
  })
  // for pagination

  //   featured news
  const [selectedFeaturedNews, setSelectedFeaturedNews] = useState(null)
  useEffect(() => {
    let selectFeatured
    if (selectedOption.value === "all") {
      selectFeatured = allFeaturedNews.find(news => news.allNews)
    } else {
      selectFeatured = allFeaturedNews.find(
        news => news.blog_category?.slug === selectedOption.value
      )
    }
    setSelectedFeaturedNews(selectFeatured)
  }, [selectedOption])
  //   featured news

  const News = currentItems.map(res => {
    return res;
  })
  const currentNews = News.filter(val => {
    if(val?.id !== selectedFeaturedNews?.featured_blog?.id){
        return val;
    }
  })

  return (
    <div className="news-listing-wrap">
      <Container className="news-listing-container">
        <div className="category-container">
          {isTablet ? (
            <Select
              options={selectOptions}
              isSearchable={false}
              className={"category-select"}
              classNamePrefix={"category-select"}
              //   hideSelectedOptions={true}
              value={selectedOption}
              onChange={handleTabChange}
            />
          ) : (
            <div className="category-tab-section">
              {selectOptions?.map(option => (
                <button
                  className={`category-tab button button-primary ${
                    selectedOption.value === option.value ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(option)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="news-section">
          {selectedFeaturedNews && (
            <NewsCardFeatured news={selectedFeaturedNews.featured_blog} />
          )}
          <div className="newsletter-section">
            <h3 className="title">Stay up to date with our newsletter</h3>
            <NewsletterForm />
            <div className="newsletter-email-terms">
              By submitting the form, you agree to our{" "}
              <Link to={termsURL}>Terms & Conditions</Link> and{" "}
              <Link to={privacyURL}>Privacy Policy</Link>.
            </div>
          </div>
          <div className="news-grid">
            {currentItems?.map(news => (
              <NewsCard news={news} key={news.id} />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={selectedNews.length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Container>
    </div>
  )
}

export default NewsListing
