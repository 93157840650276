import { Link } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Breadcrumb, Col, Container, Row } from "react-bootstrap"
import { contactPageURL, listPropertyURL } from "../../site/urls"
import "./StaticModule.scss"

const StaticModule = ({ module, page }) => {
  const content = module?.text_content
  const newsDetail = undefined
  const props = undefined

  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 250)
    })
  }, [])
  return (
    <div className="static-module-page">
      <section className="breadcrumb-wrapper-module">
        <Container>
          <Row>
            <Col>
              <Breadcrumb className="breadcrumb-module d-flex align-items-center">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>{page.title}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col xl={8}>
            <div className="news-details-heading-wrapper">
              <h1 className="news-details-heading">{page.title}</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8}>
            <div className="news-text-wrapper">{parse(content)}</div>
          </Col>
          <Col xl={1}></Col>
          <Col xl={3} className="d-xl-block d-none">
            <div
              className={`news-right-side-details sticky-top ${
                scroll ? "scrolled" : ""
              }`}
            >
              <div className="nego-details-wrapper">
                <div className="nego-btn-wrapper">
                  <Link to={contactPageURL} className="button button-primary">
                    Contact Us
                  </Link>
                  <Link
                    to={listPropertyURL}
                    className="button button-secondary"
                  >
                    List your Property
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StaticModule
