import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./TeamSlider.scss"
import _ from "lodash"
import TeamCard from "../TeamCard/TeamCard"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { sort } from "../../site/utils"

const TeamSlider = ({ module }) => {
  const data = useStaticQuery(graphql`
    query GetAllTeamsSlider {
      glstrapi {
        teams(limit: 18) {
          id
          tile_image {
            url
          }
          ggfx_results {
            src_cftle
            transforms
          }
          slug
          name
          sort
          mobile_no
          designation
          email
        }
      }
    }
  `)

  var teams =  data.glstrapi.teams; 


  if(!module?.showAllTeam){ 
    if(module?.team_speciality){
      teams = module?.team_speciality?.teams
    }else{
      teams = module?.team_category?.teams
    } 
  }

  teams?.sort((a,b) => {
    return sort(a.sort, b.sort)
  })

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: teams.length > 6,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: teams.length > 5,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: teams.length > 4,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: teams.length > 3,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: teams.length > 2,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  const { isMobile, isTablet } = useDeviceMedia()

  const slicedTeams = isTablet
    ? isMobile
      ? teams.slice(0, 8)
      : teams.slice(0, 12)
    : teams

  return (
    <section className="team-slider-wrapper section-m">
      <Container>
        <Row>
          <Col className="text-center">
            <h4 className="small-header-text">{module?.heading}</h4>
            <h2>{module?.title}</h2>
            {!_.isEmpty(slicedTeams) && (
              <Slider className="team-slider" {...settings}>
                {slicedTeams.map(team => (
                  <TeamCard team={team} normalImg />
                ))}
              </Slider>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TeamSlider
