import { Link } from "gatsby"
import React, { useState } from "react"
import "./VideoListing.scss"
import { format } from "date-fns"
import PlayVideo from "../PlayVideo/PlayVideo"
import { ImageModule } from "../common/Image_Module"
import NoImg from "../../images/no-image.png";

const VideoCard = ({ videos }) => {
  const [isPlay, setPlay] = useState(false)
  if (!videos) return null

  const postedDate = format(new Date(videos.date), "do MMM yyyy")

  return (
    <div className="videos-card-wrap">
      <div key={videos.id} className="videos-card-img">
        {videos.video_url && (
          <a
            className="btn-play icon icon-play"
            onClick={() => setPlay(true)}
          ></a>
        )}
        {videos.thumbnail.url ? (
          <ImageModule
            imagename={"video.thumbnail.tileimg"}
            ImageSrc={videos.thumbnail}
            ggfx_results={videos.ggfx_results}
            altText={videos.title}
            strapi_id={videos.id}
            className="videos-img"
          />
        ) : (
          <img src={NoImg} alt={"videos - img"} className="videos-img" />
        )}
      </div>

      <div className="videos-content">
        <Link className="videos-title">{videos?.title}</Link>
        <div className="videos-details">
          <p className="videos-date">{postedDate}</p>
          <div className="slant-divider"></div>
          <p className="videos-category">{videos?.video_category?.title}</p>
        </div>
      </div>
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={videos.video_url}
          htmlink={""}
        />
      )}
    </div>
  )
}

export default VideoCard
