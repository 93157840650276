import { format } from "date-fns"
import { Link } from "gatsby"
import React from "react"
import { newsPageURL } from "../../site/urls"
import { ImageModule } from "../common/Image_Module"
import NoImg from "../../images/no-image.png";
import "./NewsCard.scss"

const NewsCardFeatured = ({ news }) => {
  if (!news) return null

  const postedDate = format(new Date(news.post_on), "do MMM yyyy")

  return (
    <div key={news.id} className="featured-card-wrap">
      <Link
        to={newsPageURL + news.slug + "/"}
        className="img-zoom featured-img-section"
      >
        {news.tile_image.url ? (
          <ImageModule
            imagename={"blog.tile_image.featuredimg"}
            ImageSrc={news.tile_image}
            ggfx_results={news.ggfx_results}
            altText={news.title}
            strapi_id={news.id}
            className="featured-img"
          />
        ) : (
          <img src={NoImg} alt={"blog - img"} className="featured-img" />
        )}
        <p className="featured-tag">featured</p>
      </Link>
      <div className="featured-content-wrap">
        <div className="featured-content">
          <h3 className="title">{news.title}</h3>
          <div className="details">
            <p className="date">{postedDate}</p>
            {news.blog_category && (
              <>
                <div className="slant-divider"></div>
                <p className="category">{news.blog_category?.name}</p>
              </>
            )}
          </div>
          <p className="short-description">{news.short_description}</p>
        </div>
        <div className="news-cta">
          <Link
            to={newsPageURL + news.slug + "/"}
            className="button button-secondary"
          >
            view article
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NewsCardFeatured
