import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/Seo/seo"

import "../scss/global.scss"
import CommonTemplate from "./common-template"

function DefaultTemplate(props) {
  const GQLPage = props.data?.glstrapi?.page
  const GQLModules = GQLPage?.add_components
  const locationState = props.location.state
  const seo_img = GQLPage?.banner_section?.banner_image?.url_sharp?.childImageSharp ? `${process.env.GATSBY_SITE_URL}${GQLPage.banner_section.banner_image.url_sharp.childImageSharp.resize.src}` : null;

  return (
    <>
      <SEO
        title={GQLPage.seo_meta ? GQLPage.seo_meta.title : GQLPage.title}
        description={GQLPage.seo_meta ? GQLPage.seo_meta.description : ""}
        image={seo_img}
      />
      <CommonTemplate
        GQLPage={GQLPage}
        GQLModules={GQLModules}
        locationState={locationState}
      />
    </>
  )
}

export default DefaultTemplate

export const pageQuery = graphql`
  query GetPage($id: ID!) {
    glstrapi {
      page(id: $id) {
        alias
        seo_meta {
          title
          keywords
          description
          id
        }
        ggfx_results {
          src_cftle
          transforms
        }
        banner_section {
          banner_image {
            url
            url_sharp {
            childImageSharp {
              resize(width: 400) {
                src
                height
                width
              }
            }
          }
          }
          content
          id
          show_reviews
          show_search
          title
          ctas {
            custom_link
            id
            label
            theme
            target
            menu_item {
              label
              id
              slug
              parent {
                label
                slug
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
          }
        }
        choose_form
        id
        layout
        menu {
          slug
          label
          id
          parent {
            label
            slug
          }
        }
        page_class
        publish
        Popular_Search
        popular_searches {
          id
        }
        title
        imagetransforms

        add_components {
          ... on GLSTRAPI_ComponentModulesAboutAndTeamsModule {
            __typename
            id
            heading
            module_content
            teams {
              id
              tile_image {
                url
              }
              ggfx_results {
                src_cftle
                transforms
              }
              slug
              name
              mobile_no
              designation
              email
              sort
            }
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesNewsListing {
            __typename
            id
            show_newsletter

            featured_blogs {
              allNews
              id
              featured_blog {
                title
                post_on
                id
                blog_category {
                  name
                  slug
                  id
                }
                short_description
                tile_image {
                  url
                }
                ggfx_results {
                  src_cftle
                  transforms
                }
                slug
              }
              category_name
              blog_category {
                slug
                name
                id
              }
            }
          }

          ... on GLSTRAPI_ComponentBlocksRichText {
            __typename
            id
            text_content
          }

          ... on GLSTRAPI_ComponentModulesReviewsModule {
            id
            __typename
            heading
            title
            video_reviews {
              embed_video_url
              id
              image {
                url
              }
            }
            reviews_text_module {
              id
              reviews {
                customer_name
                ratings
                review_content
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsUserfulLinksModule {
            __typename
            id
            title
            heading
            link_items {
              id
              description
              image {
                url
              }
              cta {
                custom_link
                id
                label
                theme
                target
                menu_item {
                  label
                  id
                  slug
                  parent {
                    label
                    slug
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesBookAValuation {
            __typename
            id
            content
            heading
            title
            icon_descriptions {
              stat_text
              stat_description
              id
              icon_class
            }
          }

          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            __typename
            id
            choose_collection
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            description
            title
            heading
            theme
          }

          ... on GLSTRAPI_ComponentModulesSelectModule {
            __typename
            id
            module_content
            module
            title
          }

          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            id
            title
            heading
            faq {
              question
              id
              answer
            }
          }

          ... on GLSTRAPI_ComponentModulesStatsBanner {
            __typename
            id
            content
            heading
            stats {
              stat_description
              stat_text
              icon_class
              id
            }
          }

          ... on GLSTRAPI_ComponentModulesTeamSlider {
            __typename
            id
            showAllTeam
            heading
            title
            team_category {
              id
              label
              url
              teams {
                id
                tile_image {
                  url
                }
                ggfx_results {
                  src_cftle
                  transforms
                }
                slug
                name
                sort
                mobile_no
                designation
                email
              }
            }
            team_speciality {
              id
              label
              url
              teams {
                tile_image {
                  url
                }
                ggfx_results {
                  src_cftle
                  transforms
                }
                slug
                name
                sort
                mobile_no
                designation
                email
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesTileBlock {
            __typename
            id
            _id
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            flag
            tile_block_content
            title
            video_url
            tile_image {
              url
            }
          }
          ... on GLSTRAPI_ComponentModulesHomeTileBlock {
            __typename
            id
            _id
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            title
            content
            tile_flag
            Video_URL
            home_tile_image {
              url
            }
          }
        }
      }
    }
  }
`
