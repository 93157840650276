import React from "react"
import "./MakeAComplaint.scss"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import _ from "lodash"
import MakeAComplaintForm from "../forms/MakeAComplaintForm"

const MakeAComplaint = ({ module, layout }) => {

  const isMainModule = layout === "contact_page_template"

  const { title, heading, content, icon_descriptions } = module

  return (
    <div className="complaint-module-wrapper margin-for-header section-p">
      <Container className="complaint-module-container">
        <h4 className="module-heading">{heading}</h4>
        <div className="grid-container">
          <div className="left-section">
            {
              <div className="content">
                {isMainModule ? <h1>{title}</h1> : <h2>{title}</h2>}
                <div className="description">{parse(content)}</div>
              </div>
            }
            <div className="icon-description-section">
              {!_.isEmpty(icon_descriptions) &&
                icon_descriptions.map(item => (
                  <div className="complaint-item-box">
                    {item?.icon_class && <i className={`icon ${item.icon_class}`}></i>}
                    <div className={`${item?.icon_class === '' ? "m-0" : ''} complaint-details`}>
                      <p className="complaint-detail-label">{item.stat_text}</p>
                      <p className="complaint-detail">
                        {parse(item.stat_description)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="right-section">
            <MakeAComplaintForm />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default MakeAComplaint
