import React, { useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import "react-modal-video/scss/modal-video.scss"
import { isIOS, isMobile } from "react-device-detect"
import ReactPlayer from "react-player"
import getVideoId from "get-video-id"
import "./assets/styles/_index.scss"

const PlayVideo = props => {
  const [fullscreen, setFullscreen] = useState(true)
  const vidRef = useRef(null)

  var video_url = ""
  const videoid = props.videourl && getVideoId(props.videourl)
  if (videoid) {
    video_url = "https://www.youtube-nocookie.com/embed/" + videoid.id
  }

  if (props.videourl) {
    return (
      <React.Fragment>
        <Modal
          show={props.isOpen}
          onHide={() => props.isCloseFunction(false)}
          dialogClassName="modal-fullscreen modal-video"
          aria-labelledby="example-custom-modal-styling-title"
          backdropClassName="video-backdrop"
          fullscreen={fullscreen}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ReactPlayer
              onEnded={() => {
                props.isCloseFunction(false)
              }}
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              ref={vidRef}
              url={video_url}
              modestbranding="1"
              controls={isMobile && isIOS ? true : true}
              autoplay={true}
              muted={isMobile && isIOS ? true : false}
              playsinline={true}
              playing={props.isOpen}
              width="100%"
              height="100%"
            />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  } else if (props.htmlink) {
    return (
      <React.Fragment>
        <Modal
          show={props.isOpen}
          onHide={() => props.isCloseFunction(false)}
          dialogClassName="modal-fullscreen modal-video"
          aria-labelledby="example-custom-modal-styling-title"
          backdropClassName="video-backdrop"
          fullscreen={fullscreen}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <iframe src={props.htmlink} width="100%" height="100%" />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  } else {
    return <empty></empty>
  }
}

export default PlayVideo
