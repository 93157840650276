import parse from "html-react-parser"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import CareersForm from "../forms/CareersForm"
import "./SendCvModule.scss"

const SendCvModule = ({ module, layout }) => {
  const { title, heading, content, icon_descriptions } = module

  const isMainModule = layout === "contact_page_template"

  return (
    <div className="send-cv-module-wrapper margin-for-header section-p">
      <Container className="send-cv-module-container">
        <h4 className="module-heading">{heading}</h4>
        <div className="grid-container">
          <div className="left-section">
            {
              <div className="content">
                {isMainModule ? <h1>{title}</h1> : <h2>{title}</h2>}
                <div className="description">{parse(content)}</div>
              </div>
            }
            <div className="icon-description-section">
              {!_.isEmpty(icon_descriptions) &&
                icon_descriptions.map(item => (
                  <div className="send-cv-item-box">
                    <i className={`icon ${item.icon_class}`}></i>
                    <div className="send-cv-details">
                      <p className="send-cv-detail-label">{item.stat_text}</p>
                      <p className="send-cv-detail">{parse(item.stat_description)}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="right-section">
            <CareersForm />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SendCvModule
