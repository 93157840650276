import React from 'react';
// import { Container, Col, Row, Form } from "react-bootstrap";
import { Link } from "gatsby"
import './assets/styles/_index.scss';
import { ShowProcessedImage } from "../common/stb-jamstack-ggfxclient/module/components/image/show-image";
import imageConfig from "../../../static/images/config.json"
import NoImg from "../../images/no-image.png"

const PropertyCard = (props) => {
    return (
        <div className="property-card-wrapper">
            <div className="property-card-img-zoom">
                {
                    props?.img && props?.img.length > 0 ?
                    <Link to={props.propertyDetailsLink}>
                        {props.normalImg ? <img src={props?.img[0].url} alt={props.propertyAddress + " - White & Co Real Estate"} />
                         : <ShowProcessedImage
                            images={props?.img && props?.img[0]}
                            attr={{
                                className: "img-fluid",
                                alt: props.propertyAddress + " - White & Co Real Estate",
                            }}
                            transforms={imageConfig.property.searchResults.sizes}
                        />}
                    </Link>
                    :
                    <Link to={props.propertyDetailsLink}>
                        <img src={NoImg} alt="no image" />
                    </Link>
                }
            </div>
            <div className="property-card-text-wrapper"> 
                {
                    props.propertyDescription &&
                    <p className="display-address display-desc">{props.propertyDescription}</p>
                }
                {
                    props.propertyPrice &&
                    <div className="price-details">{props.propertyPrice.toLocaleString()} {props.propertyCurrency}</div>
                }
                {
                    props.propertyAddress &&
                    <p className="display-address">{props.propertyAddress}</p>
                }
                {
                    props.department !== "commercial" && props.proeprtyArea &&
                    // <div className="location-details d-flex align-items-center">
                    <div className="location-details d-flex">
                        <i className="icon icon-map-pin"></i>
                        <span>{props.proeprtyArea}</span>
                    </div>
                }
                {props.department === "commercial" && props.proeprtySqft &&
                 <div className="location-details d-flex">
                    <i className="icon icon-sqft"></i>
                    <span>{props.proeprtySqft.toLocaleString()} sqft</span>
                </div>
                }
            </div>
        </div>
    )
}

export default PropertyCard