import { useLocation } from "@reach/router"
import { parse as parseSearch } from "query-string"
import React from "react"
import { Container } from "react-bootstrap"
import { GetOffPlanPropertyBooking } from "../../queries/common_use_query"
import { offplanRentResultsURL, offplanSaleResultsURL } from "../../site/urls"
import BreadcrumbModule from "../BreadcrumbModule/BreadcrumbModule"
import RegisterInterestForm from "../forms/RegisterInterestForm"
import OffplanPropertyCard from "../SearchResults/OffplanPropertyCard"
import "./RegisterInterest.scss"

const RegisterInterest = ({ module, layout }) => {
  const { title, heading, content, icon_descriptions } = module

  const isMainModule = layout === "contact_page_template"

  const location = useLocation()
  const searchParams = parseSearch(location.search)
  const pid = searchParams.pid && searchParams.pid.replace(/\/$/, "")

  const { data } = GetOffPlanPropertyBooking(pid)

  const property = data?.offplan
  let propertyBreadcrumb = {}

  let uriStr = ""

  if (property?.search_type === "sales") {
    uriStr = `off-plan-property-for-sale/`
    propertyBreadcrumb.name = "Offplan Property for Sale"
    propertyBreadcrumb.url = offplanSaleResultsURL
  } else if (property?.search_type === "lettings") {
    uriStr = `off-plan-property-for-rent/`
    propertyBreadcrumb.name = "Offplan Property for Rent"
    propertyBreadcrumb.url = offplanRentResultsURL
  }

  const propertyURL = `/${uriStr}${property?.slug}-${property?.id}/`

  const breadcrumbData = [
    propertyBreadcrumb,
    { name: property?.display_address, url: propertyURL },
    { name: "Register your Interest", active: true },
  ]

  return (
    <>
      <BreadcrumbModule tag="form_page" data={breadcrumbData} />
      <div className="register-module-wrapper margin-for-header section-p">
        <Container className="register-module-container">
          <h4 className="module-heading">{heading}</h4>
          <div className="grid-container">
            <div className="left-section">
              {
                <div className="content">
                {isMainModule ? <h1>{title}</h1> : <h3>{title}</h3>}
                </div>
              }
              {property && (
                <div className="property-description-section">
                  <OffplanPropertyCard
                    img={property?.images}
                    propertyPrice={property?.price}
                    propertyCurrency={property?.currency}
                    propertyAddress={property?.display_address}
                    proeprtyArea={property?.area}
                    propertyDetailsLink={propertyURL}
                    imagetransforms={property?.imagetransforms}
                    id={property?.id}
                    normalImg
                    isRegisterForm
                  />
                </div>
              )}
            </div>

            <div className="right-section">
              <RegisterInterestForm
                property={property}
                propertyURL={process.env.GATSBY_SITE_URL + propertyURL}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default RegisterInterest
