import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import starsImg from "../../images/icons/review-stars.svg";
import googleLogo from "../../images/icons/icon-google.svg";
import useDeviceMedia from "../../hooks/useDeviceMedia";
import "./ReviewsSummary.scss";

const ReviewsSummary = () => {
  const { isMobile } = useDeviceMedia()
  const data = useStaticQuery(graphql`
    query GetReviewsData {
      glstrapi {
        reviews(where: { publish: true }) {        
          id
          ratings
        }
      }
    }
  `);

  const reviews = data.glstrapi.reviews;

// Convert ratings from text to numbers
const validRatings = reviews
.map(review => parseFloat(review.ratings))
.filter(rating => !isNaN(rating)); // Filter out invalid numbers

const totalReviews = validRatings.length;
const averageRating = totalReviews > 0
? (validRatings.reduce((sum, rating) => sum + rating, 0) / totalReviews).toFixed(1)
: '0.0';

const fullStars = Math.floor(averageRating);
const halfStar = averageRating % 1 >= 0.5;
const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
      <div className="reviews-summary">
          <div className="reviews">
              <div className="stars">
                 {/*  <img src={starsImg} alt="Customer Reviews - White & Co Real Estate" className="stars-img" />
                  <div className="star-icons">
                  {[...Array(5)].map((_, i) => (
                      <span key={i} className={i < Math.floor(averageRating) ? 'filled-star' : 'empty-star'}>
                          {i < Math.floor(averageRating) ? '★' : '☆'}
                      </span>
                  ))}
                  </div> */}
                   <div className="star-icons">
                    {[...Array(fullStars)].map((_, i) => (
                    <span key={`filled-${i}`} className="filled-star"><i className="icon icon-full-star"></i></span>
                    ))}
                    {halfStar && <span className="half-star"><i className="icon icon-half-star"></i></span>}
                    {[...Array(emptyStars)].map((_, i) => (
                    <span key={`empty-${i}`} className="empty-star"><i className="icon icon-empty-star"></i></span>
                    ))}
                </div>
              </div>
              <div className="rating-text">
                  <p className="review-count">
                      <strong>{averageRating}/5</strong>
                      {!isMobile && ` from ${totalReviews} Reviews`}
                  </p>
              </div>
          </div>
          {/* <div className="divider"></div>
          <img src={googleLogo} alt="google-logo" className="google-img" /> */}
      </div>
  );
};

export default ReviewsSummary;