import _ from "lodash"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import ReactSelect from "react-select"
import Slider from "react-slick"
import { ImageModule } from "../common/Image_Module"
import PlayVideo from "../PlayVideo/PlayVideo"
import ReviewTextModule from "./ReviewTextModule";
import "./ReviewsModule.scss"
import noImg from "../../images/no-image.png"

const ReviewsModule = ({ module, GQLPage }) => {
  let settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  const VideoReviewItem = ({ review }) => {
    const [isPlay, setPlay] = useState(false)
    return (
      <div className="video-review-item-wrap">
        {review?.image?.url ? (
          <ImageModule
            imagename={"pages.video_reviews_image.image"}
            ImageSrc={review.image}
            ggfx_results={GQLPage.ggfx_results}
            altText={"video-review-image"}
            strapi_id={GQLPage.id}
            className="video-img"
          />
        ) : (
          <img src={noImg} alt="video-review-image" className="video-img" />
        )}
        <a
          className="btn-play icon icon-play"
          onClick={() => setPlay(true)}
        ></a>
        {isPlay && (
          <PlayVideo
            isOpen={isPlay}
            stopPlay={setPlay}
            videoId=""
            isCloseFunction={setPlay}
            videourl={review.embed_video_url}
            htmlink={""}
          />
        )}
      </div>
    )
  }

  return (
    <div className="review-module-wrapper section-p">
      <Container className="review-module-container">
        <div className="review-video-module">
        {!_.isEmpty(module?.video_reviews) && (
          <Slider className="video-reviews-section" {...settings}>
            {module?.video_reviews.map(review => (
              <VideoReviewItem review={review} />
            ))}
          </Slider>
        )}
        </div>
        <div className="review-text-module">
          <div className="reviews-text-header">    
            {module?.heading && <h6 className="small-header-text">{module.heading}</h6>}
            {module?.title && <h5>{module.title}</h5>}
          </div>
          {module?.reviews_text_module?.reviews?.length > 0 && (
            <ReviewTextModule reviews={module?.reviews_text_module?.reviews} />
          )}
        </div>
      </Container>
    </div>
  )
}

export default ReviewsModule
