import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import "./assets/styles/_index.scss"

import parse from "html-react-parser"
import { GetPageURL } from "../common/site/utils"

const ListYourPropertyBanner = () => {
  const data = useStaticQuery(graphql`
    query GetListYourPropertyBanner {
      glstrapi {
        globalModule {
          list_your_property {
            heading
            id
            text_content
            title
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { heading, text_content, ctas } =
    data.glstrapi.globalModule.list_your_property

  return (
    <section className="list-your-property-banner-wrapper">
      <Container>
        <Row>
          <Col className="text-center">
            <h2 className="banner-title">{heading}</h2>
            {text_content && (
              <div className="banner-content">{parse(text_content)}</div>
            )}
            <div className="banner-btn-wrapper d-flex justify-content-center">
              {ctas?.map(cta => (
                <Link
                  to={GetPageURL(cta.menu_item, cta.custom_link)}
                  className="button button-white"
                >
                  {cta.label}
                </Link>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ListYourPropertyBanner
