import React, { useRef } from "react"
import { formTracking } from "../../site/utils"
import { SendMail } from "../common/site/utils"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import TextInput from "./elements/TextInput"
import Recaptcha from "./inputs/Recaptcha"
import { makeAComplaintSchema } from "./schemas"

const MakeAComplaintForm = () => {
  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.make_a_complaint }
      postData.name = postData.fname + " " + postData.lname
      //Shallow clone for Google data
      postData["google_data"] = {...postData}
      postData['g-recaptcha-response'] = token;
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.make_a_complaint.event_tracking,
        form_name: FORM_CONFIG.make_a_complaint.form_name,
      })
    }
  }

  return (
    <>
      <Form
        className="base-form general-enquiry-form"
        validationSchema={makeAComplaintSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput name="fname" label="First Name" />
        <TextInput name="lname" label="Last Name" />
        <TextInput name="email" label="Email" type="email" />
        <TextInput name="telephone" label="Contact Number" type="number" />
        <TextInput
          name="nationality"
          label="Nationality"
          value="United Arab Emirates"
        />
        <button type="submit" className="button button-primary">
          Make a Complaint
        </button>
        <TermsPolicySection />
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
    </>
  )
}

export default MakeAComplaintForm
