import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import "./AwardsSlider.scss"
import _ from "lodash"
import TeamCard from "../TeamCard/TeamCard"
import { ImageModule } from "../common/Image_Module"
import NoImg from "../../images/no-image.png";

const AwardsSlider = ({ module }) => {

  const data = useStaticQuery(graphql`
    query GetAllAwards {
      glstrapi {
        awards (sort: "createdAt:desc") {
          id
          slug
          name
          description
          image {
            url
          }
          ggfx_results {
            src_cftle
            transforms
          }
        }
      }
    }
  `)

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  const awards = data.glstrapi.awards

  return (
    <section className="awards-slider-wrapper section-p">
      <Container>
        <Row>
          <Col className="text-center">
            <h4 className="small-header-text">{module?.title}</h4>
            <h2>{module?.heading}</h2>
            {!_.isEmpty(awards) && (
              <Slider className="awards-slider" {...settings}>
                {awards.map(award => (
                  <div className="awards-card-wrap">
                    {award.image.url ? (
                      <ImageModule
                        imagename={"awards.image.tileimg"}
                        ImageSrc={award.image}
                        ggfx_results={award.ggfx_results}
                        altText={award.name + " - White & Co Real Estate"}
                        strapi_id={award.id}
                        className="award-img"
                      />
                    ) : (
                      <img src={NoImg} alt={"awards - img"} className="award-img" />
                    )}
                    <p className="name">{award.name}</p>
                    <p className="description">{award.description}</p>
                  </div>
                ))}
              </Slider>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AwardsSlider
