import { useLocation } from "@reach/router"
import parse from "html-react-parser"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { GetPropertyBooking } from "../../queries/common_use_query"
import BookViewingForm from "../forms/BookViewingForm"
import { parse as parseSearch } from "query-string"
import "./BookViewingModule.scss"
import PropertyCard from "../SearchResults/PropertyCard"
import BreadcrumbModule from "../BreadcrumbModule/BreadcrumbModule"
import { propertyRentResultsURL, propertySaleResultsURL, propertyCommercialSaleResultsURL, propertyCommercialRentResultsURL } from "../../site/urls"

const BookViewingModule = ({ module, layout }) => {
  const { title, heading, content, icon_descriptions } = module

  const isMainModule = layout === "contact_page_template"

  const location = useLocation()
  const searchParams = parseSearch(location.search)
  const pid = searchParams.pid && searchParams.pid.replace(/\/$/, "")

  const { data } = GetPropertyBooking(pid)

  const property = data?.property

  let uriStr = ""
  let propertyBreadcrumb = {}

  if (
    property?.search_type === "sales" &&
    property?.department === "residential"
  ) {
    uriStr = `property-for-sale/`
    propertyBreadcrumb.name = "Property for Sale"
    propertyBreadcrumb.url = propertySaleResultsURL
  } else if (
    property?.search_type === "lettings" &&
    property?.department === "residential"
  ) {
    uriStr = `property-for-rent/`
    propertyBreadcrumb.name = "Property for Rent"
    propertyBreadcrumb.url = propertyRentResultsURL
  }else if (
    property?.search_type === "sales" &&
    property?.department === "commercial"
  ) {
    uriStr = `commercial-property-for-sale/`
    propertyBreadcrumb.name = "Commercial Property for Sale"
    propertyBreadcrumb.url = propertyCommercialSaleResultsURL
  }else if (
    property?.search_type === "lettings" &&
    property?.department === "commercial"
  ) {
    uriStr = `commercial-property-for-rent/`
    propertyBreadcrumb.name = "Commercial Property for Rent"
    propertyBreadcrumb.url = propertyCommercialRentResultsURL
  }

  const propertyURL = `/${uriStr}${property?.slug}-${property?.id}/`

  const breadcrumbData = [
    propertyBreadcrumb,
    { name: property?.display_address, url: propertyURL },
    { name: "Book a Viewing", active: true },
  ]

  return (
    <>
      <BreadcrumbModule tag="form_page" data={breadcrumbData} />
      <div className="book-viewing-module-wrapper margin-for-header section-p">
        <Container className="book-viewing-module-container">
          <h4 className="module-heading">{heading}</h4>
          <div className="grid-container">
            <div className="left-section">
              {
                <div className="content">
                {isMainModule ? <h1>{title}</h1> : <h3>{title}</h3>}
                </div>
              }
              {property && (
                <div className="property-description-section">
                  <PropertyCard
                    img={property?.images}
                    propertyPrice={property?.price}
                    propertyCurrency={property?.currency}
                    propertyAddress={property?.display_address}
                    proeprtyArea={property?.area}
                    propertyDetailsLink={propertyURL}
                    normalImg
                  />
                </div>
              )}
            </div>

            <div className="right-section">
              <BookViewingForm
                property={property}
                propertyURL={process.env.GATSBY_SITE_URL + propertyURL}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default BookViewingModule
