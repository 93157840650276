import React, { useRef } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { formTracking } from "../../site/utils"
import { SendMail } from "../common/site/utils"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import DatePicker from "./elements/DatePicker"
import Form from "./elements/Form"
import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import TimePicker from "./elements/TimePicker"
import Recaptcha from "./inputs/Recaptcha"
import { bookViewingSchema } from "./schemas"

const BookViewingForm = ({ property, propertyURL }) => {
  const recaptchaRef = useRef()

  const onSubmit = async data => {
   const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.book_viewing }
      postData["property_address"] = property?.display_address
      postData["property_url"] = propertyURL
      //Shallow clone for Google data
      postData['g-recaptcha-response'] = token;
      postData["google_data"] = {...postData}
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.book_viewing.event_tracking,
        form_name: FORM_CONFIG.book_viewing.form_name,
      })
    }
  }

  return (
    <>
      <Form
        className="base-form general-enquiry-form"
        validationSchema={bookViewingSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput name="name" label="Name" />
        <TextInput name="email" label="Email" type="email" />
        <TextInput name="telephone" label="Phone Number" type="number" />
        <DatePicker name="date" label="Preferred Date" />
        <TimePicker name="time" label="Preferred Time" />
        <TextAreaInput name="message" label="Message" />
        <button type="submit" className="button button-primary">
          submit enquiry
        </button>
        <TermsPolicySection />
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
    </>
  )
}

export default BookViewingForm
