import React from 'react';
import { Container, Col, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import _ from "lodash"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetGGFXImage from '../common/site/get-ggfx-image';
import { ImageModule } from "../common/Image_Module"
import './assets/styles/_index.scss';
import clsx from 'clsx';
import NoImg from "../../images/no-image.png"

const CommercialSlider = ({module}) => {

    // Sales
    const DATA_SALES = gql`
        query GetProperty {
            properties (
                limit: 6
                sort: "price:DESC"
                where:{search_type: "sales", publish: "true", department: "commercial"}) {
                id
                area
                display_address
                slug
                search_type
                publish
                price
                currency
                images
                imagetransforms
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
        }
    `;

    const { data: dataSales } = useQuery(DATA_SALES);
    // Sales

    // Lettings
    const DATA_LETTINGS = gql`
        query GetProperty {
            properties (
                limit: 6
                sort: "price:DESC"
                where:{search_type: "lettings", publish: "true", department: "commercial"}) {
                id
                area
                display_address
                slug
                search_type
                publish
                price
                currency
                images
                imagetransforms
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
        }
    `;

    const { data: dataLettings } = useQuery(DATA_LETTINGS);
    // Lettings 

    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    // get property detail url
    const getPropertyUrl = (item) => {
        if (item.search_type === 'sales') {
            return '/property-for-sale/'+item.slug+"-"+item.id+"/"; 
        } else {
            return '/property-for-rent/'+item.slug+"-"+item.id+"/"; 
        }
    }

    return (
        <section className={clsx('be-inspired-slider-wrapper section-p', {'bg-gray': module?.theme === 'gray'})}>
            <Container>
                <Row>
                    <Col className="text-center">
                        <p className="small-header-text">{module?.title ? module?.title : "Be Inspired"}</p>
                        <h2>{module?.heading ? module?.heading : "Handpicked Properties"}</h2>
                        <Tabs
                            defaultActiveKey="commercial-buy"
                            className="be-inspired-tab d-flex justify-content-center"
                        >
                            {
                                !_.isEmpty(dataSales?.properties) &&
                                <Tab eventKey="commercial-buy" title="Commercial Properties for Sale">
                                    <Slider className="be-inspired-slider" {...settings} infinite={true}>
                                        {
                                            dataSales?.properties.map((item, key) => {

                                                let processedImages = JSON.stringify({});
                                                if (item?.imagetransforms?.images_Transforms) {
                                                    processedImages = item.imagetransforms.images_Transforms;
                                                }


                                                return (
                                                    <div className="be-inspired-slide" key={key}>
                                                        <div className="be-inspired-img-zoom">
                                                            <Link to={getPropertyUrl(item)}>
                                                                {!_.isEmpty(item?.images) ? <ImageModule
                                                                    imagename={"property.images.featuredProperties"}
                                                                    ImageSrc={item?.images[0]}
                                                                    ggfx_results={item?.ggfx_results}
                                                                    altText={item?.display_address}
                                                                    strapi_id={item?.id}
                                                                /> : <img src={NoImg} alt="no_img"/> }
                                                            </Link>
                                                        </div>
                                                        <div className="be-inspired-text-wrapper">
                                                            <div className="price-details">{item?.price.toLocaleString()} {item?.currency}</div>
                                                            <p className="display-address">{item?.display_address}</p>
                                                            <div className="location-details d-flex align-items-center">
                                                                <i className="icon icon-map-pin"></i>
                                                                <span>{item?.area}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </Tab>
                            }
                            {
                                !_.isEmpty(dataLettings?.properties) &&
                                <Tab eventKey="commercial-rent" title="Commercial Properties for rent">
                                    <Slider className="be-inspired-slider" {...settings} infinite={true}>
                                        {
                                            dataLettings?.properties.map((item, key) => {
                                                
                                                let processedImages = JSON.stringify({});
                                                if (item?.imagetransforms?.images_Transforms) {
                                                    processedImages = item.imagetransforms.images_Transforms;
                                                }


                                                return (
                                                    <div className="be-inspired-slide" key={key}>
                                                        <div className="be-inspired-img-zoom">
                                                            <Link to={getPropertyUrl(item)}>
                                                                {!_.isEmpty(item?.images) ? <ImageModule
                                                                    imagename={"property.images.featuredProperties"}
                                                                    ImageSrc={item?.images[0]}
                                                                    ggfx_results={item?.ggfx_results}
                                                                    altText={item?.display_address}
                                                                    strapi_id={item?.id}
                                                                /> : <img src={NoImg} alt="no_img"/> }
                                                            </Link>
                                                        </div>
                                                        <div className="be-inspired-text-wrapper">
                                                            <div className="price-details">{item?.price.toLocaleString()} {item?.currency}</div>
                                                            <p className="display-address">{item?.display_address}</p>
                                                            <div className="location-details d-flex align-items-center">
                                                                <i className="icon icon-map-pin"></i>
                                                                <span>{item?.area}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </Tab>
                            } 
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CommercialSlider