import React from "react"
import "./ContactModule.scss"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { getWhatsAppURL, scrollToElement } from "../../site/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const ContactModule = ({ module, layout }) => {
  const data = useStaticQuery(graphql`
    query GetContactInfo {
      glstrapi {
        siteConfig {
          email
          phone
          address_link
          address
        }
      }
    }
  `)

  const contact = data.glstrapi.siteConfig
  const { title, module_content } = module
  const { isMobile, isTablet } = useDeviceMedia()

  const isMainModule = layout === "contact_page_template"

  const goToEnquiry = () => {
    const element = document.getElementById("enquiry-module")
    var scrollOffset = isMobile ? -40 : isTablet ? -50 : -120
    scrollToElement(element, scrollOffset)
  }

  return (
    <div className="contact-module-wrapper margin-for-header section-p">
      <Container className="contact-module-container">
        <div className="left-section">
        {isMainModule ? <h1 className="title">{title}</h1> : <h4 className="title">{title}</h4>}
          {module_content && (
            <div className="content">{parse(module_content)}</div>
          )}
        </div>
        <div className="right-section">
          <div className="contact-item-box">
            <i className="icon icon-phone-light"></i>
            <div className="contact-details">
              <p className="contact-detail-label">Phone</p>
              <a href={`tel:${contact.phone}`} className="contact-detail">
                {contact.phone}
              </a>
            </div>
          </div>
          <div className="contact-item-box">
            <i className="icon icon-mail-light"></i>
            <div className="contact-details">
              <p className="contact-detail-label">Email</p>
              <a
                onClick={() => goToEnquiry()}
                href={`javascript:void(0)`}
                className="contact-detail"
              >
                {contact.email}
              </a>
            </div>
          </div>
          <div className="contact-item-box">
            <i className="icon icon-whatsapp-light"></i>
            <div className="contact-details">
              <p className="contact-detail-label">WhatsApp</p>
              <a
                href={getWhatsAppURL(contact.phone)}
                target="_blank"
                className="contact-detail"
              >
                Click to WhatsApp
              </a>
            </div>
          </div>
          <div className="contact-item-box">
            <i className="icon icon-location-light"></i>
            <div className="contact-details">
              <p className="contact-detail-label">Office Address</p>
              <a
                href={contact.address_link}
                target="_blank"
                className="contact-detail"
              >
                {contact.address}
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ContactModule
