import React, { useRef } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { formTracking } from "../../site/utils"
import { SendMail } from "../common/site/utils"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import Recaptcha from "./inputs/Recaptcha"
import { makeAnEnquirySchema } from "./schemas"

const MakeEnquiryForm = ({ property, propertyURL }) => {
  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.register_interest }
      postData.property_address = property?.display_address
      postData.property_url = propertyURL
      //Shallow clone for Google data
      postData["google_data"] = {...postData}
      postData['g-recaptcha-response'] = token;
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.register_interest.event_tracking,
        form_name: FORM_CONFIG.register_interest.form_name,
      })
    }
  }

  return (
    <>
      <Form
        className="base-form general-enquiry-form"
        validationSchema={makeAnEnquirySchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput name="name" label="Name" />
        <TextInput name="email" label="Email" type="email" />
        <TextInput name="telephone" label="Phone Number" type="number" />
        <TextAreaInput name="message" label="Message" />
        <button type="submit" className="button button-primary">
          Make an Enquiry
        </button>
        <TermsPolicySection />
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
    </>
  )
}

export default MakeEnquiryForm
