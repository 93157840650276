import React, { useEffect, useRef } from "react"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import SearchForm from "./SearchForm"
import SearchFormHolidayHomes from "./SearchFormHolidayHomes"
import "./BannerSearch.scss"
import starsImg from "../../images/icons/review-stars.svg"
import googleLogo from "../../images/icons/icon-google.svg"
import { useMediaQuery } from "@react-hooks-library/core"
import { ImageModule } from "../common/Image_Module"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import _ from "lodash"
import { Link } from "gatsby"
import { GetPageURL } from "../common/site/utils"
import clsx from "clsx"
// import videoBg from "../../images/video_bg.mp4"

const BannerSearch = ({ bannerSection, ggfx_results, imagetransforms, id, alias }) => {
  const { isMobile } = useDeviceMedia()
  const videoEl = useRef(null)

  let processedImages = JSON.stringify({})
  if (imagetransforms?.banner_image_Transforms) {
    processedImages = imagetransforms.banner_image_Transforms
  }

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error)
      })
  }

  useEffect(() => {
    attemptPlay()
  }, [])

  return (
    <div className="banner-search-wrap">
      {/* <img
        src={bannerSection.banner_image.url}
        alt="banner img"
        className="banner-img"
      /> */}

      {alias === "home" ? (
        <video autoPlay="true" muted playsInline loop className="banner-img">
          <source src="https://ggfx-whiteandco.s3.eu-west-2.amazonaws.com/i.prod/home_banner_video_a736cf348b.mp4" type="video/mp4"/>
        </video>
      ) : (
        <ImageModule
          imagename={"pages.banner_image.bannerimg"}
          ImageSrc={bannerSection?.banner_image}
          ggfx_results={ggfx_results}
          altText={bannerSection?.title}
          strapi_id={id}
          className="banner-img"
        />
      )}
      <div className="overlay-bg"></div>
      <Container className={clsx(`banner-search-container`, {"no-search": !bannerSection.show_search})}>
        <h1 className="title">{bannerSection.title}</h1>
        {bannerSection.content && (
          <div className="content">{parse(bannerSection.content)}</div>
        )}
        {alias=="Holiday Homes" ? <SearchFormHolidayHomes alias={alias} /> :
          bannerSection.show_search && <SearchForm alias={alias} />
        }
        {!_.isEmpty(bannerSection.ctas) && (
          <div className="cta-section">
            {bannerSection.ctas.map(cta => (
              <Link
                className="button button-secondary"
                to={GetPageURL(cta.menu_item, cta.custom_link)}
              >
                {cta.label}
              </Link>
            ))}
          </div>
        )}
        {bannerSection.show_reviews && (
          <div className="review-section">
            <div className="review">
              <img src={starsImg} alt="review-stars" className="stars-img" />
              <p className="review-count">
                <strong>4.9/5</strong>
                {!isMobile && " from 460 Reviews "}
              </p>
            </div>
            <div className="divider"></div>
            <img src={googleLogo} alt="google-logo" className="google-img" />
          </div>
        )}
      </Container>
    </div>
  )
}

export default BannerSearch
