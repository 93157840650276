import _ from "lodash"
import React, { useRef } from "react"
import { formTracking } from "../../site/utils"
import { SendMail } from "../common/site/utils"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import Recaptcha from "./inputs/Recaptcha"
import { generalEnquirySchema } from "./schemas"

const GeneralEnquiryForm = ({ toEmail }) => {
  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.enquiry }
      postData["to_email_id"] = toEmail
      //Shallow clone for Google data
      postData["google_data"] = {...postData}
      postData['g-recaptcha-response'] = token;
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.enquiry.event_tracking,
        form_name: toEmail ? "Person Contact" : FORM_CONFIG.enquiry.form_name,
      })
    }
  }

  return (
    <>
      <Form
        className="base-form general-enquiry-form"
        validationSchema={generalEnquirySchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput name="name" label="Name" />
        <TextInput name="email" label="Email" type="email" />
        <TextInput
          name="telephone"
          label="Phone Number"
          type="number"
          registerOptions={{ valueAsNumber: true }}
        />
        <TextAreaInput name="message" label="Message" />
        <button type="submit" className="button button-primary">
          <span>submit enquiry</span>
        </button>
        <TermsPolicySection />
        <div className="d-none">
          <Recaptcha recaptchaRef={recaptchaRef} />
        </div>
      </Form>
    </>
  )
}

export default GeneralEnquiryForm
