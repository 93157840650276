import React from "react"
import "./BannerLanding.scss"
import parse from "html-react-parser"
import linesBg from "../../images/lines-bg.svg"
import { Container } from "react-bootstrap"
import ReviewsSummary from "../ReviewsModule/ReviewsSummary"

const BannerLanding = ({ bannerSection }) => {
  return (
    <div className="banner-landing-wrap">
      <img src={linesBg} className="lines-img" />
      <Container className="banner-landing-container">
        <h4 className="title">{bannerSection.title}</h4>
        {bannerSection?.content && (
          <div className="banner-content">{parse(bannerSection?.content)}</div>
        )}
        {bannerSection.show_reviews && (
            <ReviewsSummary />
        )}
      </Container>
    </div>
  )
}

export default BannerLanding
