import React, { useState } from "react"
import ReactDatePicker from "react-datepicker"
import { Controller, useFormContext } from "react-hook-form"
import InputBox from "./InputBox"
import { format } from "date-fns"

const TimePicker = ({ name, label }) => {
  const [startTime, setStartTime] = useState(new Date())

  const formatTime = date => format(date, "h:mm aa")

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <InputBox name={name} label={label} errors={errors}>
      <Controller
        control={control}
        name={name}
        defaultValue={formatTime(new Date())}
        render={({ field }) => (
          <ReactDatePicker
            selected={startTime}
            onChange={date => {
              setStartTime(date)
              field.onChange(formatTime(date))
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="h:mm aa"
            // minDate={startTime}
            // minTime={startTime}
            // maxTime={startTime}
          />
        )}
      />
    </InputBox>
  )
}

export default TimePicker
